<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-form ref="form" :model="formData" label-width="100px">
      <el-form-item label="店铺类型">
        <el-input style="width: 250px" size="mini" v-model="formData.name" clearable placeholder="请输入"></el-input>
      </el-form-item>
        <el-form-item label="icon">
          <UploadImg :url.sync="formData.icon"></UploadImg>
        </el-form-item>
      <el-form-item label="类型">
        <el-select style="width: 200px" v-model="formData.type" placeholder="请选择" size="mini" clearable>
          <el-option label="电竞酒店" :value="1"> </el-option>
          <el-option label="网卡电竞馆" :value="2"> </el-option>
          <el-option label="其他类型商家" :value="0"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="排序">
        <el-input-number size="mini" style="width: 250px" v-model="formData.sort" :step="1" step-strictly :min="0" placeholder="请输入"></el-input-number>
      </el-form-item>
      <el-form-item label="">
        <el-button type="" size="small" @click="$router.back()">取 消</el-button>
        <el-button type="primary" size="small" @click="save">保 存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { addAPI, editAPI, getDetailAPI } from './api'
import UploadImg from '@/components/qiniu-uploader/uploader-img.vue'
export default {
  name: 'Update',
  components: { UploadImg },
  data() {
    return {
      formData: {
        name: '',
        sort: 0,
        icon: '',
        type: 0,
      }
    }
  },

  mounted() {
    if (this.$route.params.id) this.getDetail()
  },

  methods: {
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.formData.id) {
            editAPI(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.$router.back()
            })
          } else {
            addAPI(this.formData).then(() => {
              this.$message.success('新增成功')
              this.$router.back()
            })
          }
        }
      })
    },
    async getDetail() {
      this.formData = await getDetailAPI(this.$route.params.id)
    }
  }
}
</script>
